hr {
  border: 0.0625em solid grey;
  margin-top: 20px;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 0.75em;
  margin-top: -4px;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #696969;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  margin-top: 15px;
  margin-bottom: 30px;
}
.login_image {
  height: 380px;
  width: 577px;
}
.modal_show_hide {
  display: none;
}
.setpassword_button {
  display: flex;
  justify-content: flex-end;
}
.register_message {
  font-size: 17px;
  width: auto;
  font-family: "Poppins";
}
.set_password {
  display: flex;
  flex-direction: row;
}
.randome_text2 {
  line-height: 1.1;
  font-family: "Poppins";
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin-top: 18px;
  text-align: center;
}
.randome_text3 {
  line-height: 1.1;
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  font-weight: 500;

  text-align: center;
}
.main_quarter_box {
  border: "0.0625em solid black";
  padding: 0.125em;
  background-color: rgb(204, 210, 221);
  border-radius: 7px;
  height: 30vh;
}

.footer1 {
  text-align: center;
  font-size: 12x;
  font-family: "SFS, Arial, sans-serif";
  /* position: absolute; */
  margin-top: auto;
}

.quarter1 {
  width: 22%;
}
.quarter1_header {
  width: 30%;
  display: flex;
}

.register {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .modal_show_hide {
    display: block;
  }
  .setpassword_button {
    width: 320%;
  }
  .register {
    width: 84%;
  }
  .para {
    display: none;
  }
  .quarter1_header {
    width: 0%;
    display: flex;
  }
  .main_quarter_box {
    height: 30vh;
  }
  .set_password {
    width: 320%;
  }
  .register_message {
    font-size: 0.75em;
    width: auto;
  }
  .reg_text {
    font-size: 0.6875em;
  }
  /* .footer1{
      display: none;
    } */
}
@media only screen and (max-width: 540px) {
  .register {
    width: 74%;
  }
  .para {
    display: none;
  }
  .quarter1 {
    width: 15%;
  }
  .quarter1_header {
    width: 24%;
    display: flex;
  }
  .main_quarter_box {
    height: 31vh;
  }
}
@media only screen and (min-width: 25.6875em) and (max-width: 45.6875em) {
  .register {
    width: 84%;
  }
  .quarter1 {
    width: 18%;
  }
  .para {
    display: none;
  }
  .quarter1_header {
    width: 26%;
    display: flex;
  }
  .main_quarter_box {
    height: 27vh;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .register {
    width: 63%;
  }
  .quarter1 {
    width: 11.5%;
  }
  .para {
    display: none;
  }
  .quarter1_header {
    width: 24%;
    display: flex;
  }
  .main_quarter_box {
    height: 35vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .randome_text2 {
    line-height: 1.1;
    font-family: "Poppins";
    color: black;
    font-size: 0.75em;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
  }
  .randome_text3 {
    line-height: 1.1;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.6875em;
    font-weight: 500;

    text-align: center;
  }
  .login_image {
    height: 280px;
    width: 484px;
  }
  .set_password {
    width: 120%;
  }
}

@keyframes fadeInOut {
  0% {
    color: #f7f979;
  }
  50% {
    color: orange;
  }
  100% {
    color: #f7f979;
  }
}
@keyframes fadeInOut1 {
  0% {
    color: red;
  }
  50% {
    color: orange;
  }
  100% {
    color: red;
  }
}
.ant-select-selection-selected-value {
  /* border-radius: 0px 8px 8px 0px; */
  height: 29px;
}
/* .ant-calendar-picker{
    width:100%
  } */
/* .recharts-default-legend {
    margin: -10px;
  } */
.ant-select-selection.ant-select-selection--single {
  height: 29px;
}
.stages {
  /* /width: 350px; */
  /* padding: 5px 10px; */
  border-radius: 10px;
  box-shadow: 0 0.8125em 27px -5px rgba(118, 118, 184, 0.432);
  background-color: #40a9ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
}
.popup-wrapper {
  width: 300px !important;
}
.avatar {
  justify-content: "center";
  flex-direction: "column";
}
.avatar-bg {
  width: 90px;
  height: 90px;
  padding: 0.75em 30px;
  box-shadow: 0 0.8125em 27px -5px rgba(50, 50, 93, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 80px;
}
.vl {
  border-left: 6px solid rgb(95, 93, 92);
  height: 400px;
  margin: 0.5rem;
  margin-left: 40px;
}
.edit_hover_class {
  visibility: hidden;
}
.edit_hover_class:hover {
  visibility: visible;
}
/* 
    table.glyphicon-hover .glyphicon {
      visibility: hidden;
    }
    table.glyphicon-hover td:hover .glyphicon {
      visibility: visible;
    } */
.details {
  width: 255px;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0 0.8125em 27px -5px rgba(50, 50, 93, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-left: 8px;
}
/* .cdPnlm .ant-tabs-nav .ant-tabs-tab {
    margin: 0 5px 0 0;
    padding: 0.75em 20px;
  } */
body {
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0f0f0f;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(65, 65, 65);
  outline: 0.0625em solid rgb(121, 122, 122);
  border-radius: 0.125em;
}

.DraftEditor-root {
  height: auto !important;
}
.GAxRl .ant-layout {
  padding: 0px !important;
 
}
.ant-layout-content {
  flex: auto;
  min-height: 100vh;
}

.mainDiv {
  /* position: relative; */
  right: 0;
  /* width: 200%;
    height: 200%; */
  border: 0.1875em solid blue;
}
.antbtn1 {
  background-color: teal;
  color: white;
  font-size: small;
  padding: 0px;
  width: 48%;
}
.antbtn2 {
  background-color: red;
  font-size: small;
  color: white;
  padding: 0px;
  width: 43%;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: -1em;
}
.ant-menu-sub.ant-menu-inline {
  margin-top: -0.8125em;
}
.ant-btn {
  /* height:auto; */
  align-items: center;
  color: white;
  background: #3066be;
  border-radius: 0.3em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  /* margin-top: 7px;  */
}
.ant-input-search-button {
  height: 2.1em;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  height: 29px;
  width: 100%;
  min-width: 0;
  padding: 4px 0.6875em;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 0.0625em solid #d9d9d9;
  border-radius: 0.125em;
  transition: all 0.3s;
  display: inline-flex;
}


.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 82%;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 29px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 4px 0.6875em 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 0.0625em solid #d9d9d9;
  border-radius: 0.125em;
  transition: border 0.3s, box-shadow 0.3s;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0px;
}

.ant-table-filter-trigger:hover {
  color: tomato;
  background: rgba(0, 0, 0, 0.04);
  font-weight: bold;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
}

.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: tomato;
  font-weight: bold;
  font-size: 0.75em;
  border-radius: 0.125em;
  cursor: pointer;
  transition: all 0.3s;
}

/* .ant menuitem {
    height: 65px;
  left: 39.4375em;
  top: 560px;
  
  background: #FFFFFF;
  border: 0.0625em solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  } */

/* h1 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 1.3em;
  margin: initial
} */

h2 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-family: "Poppins";
  font-size: 1.3em;
  /* margin: initial */
}

a {
  color: black;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375em;
  margin-top: -0.125em;
  line-height: 34px;
}

h3 {
  /* margin-top: 0;
    margin-bottom: 0.5em; */
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 1em;
  /* margin: initial; */
}

h4 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 0.8em;
  /* margin: initial; */
}

h5 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 0.7em;
  /* margin: initial; */
}

h6 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 0.6em;
  /* margin: initial; */
}

.AppButton {
  /* height: 35px; */
  justify-content: center;
  /* width: "50vw";  */
  background: #ffffff;
  border: 0.0625em solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 20px 0px 20px;
  color: black;
}

.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}

.cnclOrder {
  background: #3066be;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  font-style: normal;
  font-weight: 700;
}

.cnclordr {
  border: 0.1875em solid #3066be;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
  background-color: white;

  color: #3066be;
}

.crt1 {
  background: white;
  border: 0.0625em solid black;
  box-sizing: border-box;
  border-radius: "0.5em";
  color: black;
}

.cart-icon {
  display: flex;
  text-align: end;
  /* margin-right: 2rem; */
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.cart-icon img {
  width: 2rem;
  height: 2rem;
  color: #2f80ed;
}

.cart-icon p {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: -0.2rem;
  top: -0.1rem;
  border-radius: 50%;
  background: orange;
  color: #fff;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.5);
}

.ant-input {
  /* box-sizing: border-box; */
  margin: 0;
  /* padding: 0; */
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  /* display: inline-block;
      width: 100%; */
  min-width: 0;
  padding: 1px 9px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
h7 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 3.3em;
  /* margin: initial; */
}
.contactAddress {
  margin: 5% 0 0 25%;
  color: #000;
  font-size: 100%;
  font-family: "Times New Roman", Courier, monospace;
}

.imgc {
  text-align: center;
  margin: auto;
}
.MuiTooltip-tooltip {
  background-color: orange!important;
  color:white!important;
}
.MuiTooltip-arrow{
  color: orange!important;
}
.shopprLogoPng {
  width: 6em;
  @media only screen and (max-width: 600px) {
    width: 6em;
  }
}

.oKrKv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: ltr;
  margin-top: 3em;
}
.adhBtN {
  align-items: center;
  color: white;
  background: #3066be;
  border-radius: 0.3em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}